define("discourse/plugins/discourse-gamification/discourse/helpers/lessthan", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lt = lt;
  function lt(params) {
    return params[0] <= params[1];
  }
  var _default = _exports.default = _helper.default.helper(lt);
});